'use strict';
var coreClientSideValidation = require('core/components/clientSideValidation');

// Validate password requirements when loading when hitting Account-SaveNewPassword from error
$(window).on("load", function(){
    if ($('input#newPassword').length > 0) {
        var $this = $('input#newPassword');
        var form = $this[0].form;
        var value = $this.val();
        var $passwordHelper = $(form).find('.password-validation-helper li');
        $passwordHelper.trigger('checkrequirement', [value, 'valid empty']);
        $this.trigger('validatepassword');
    }
});

module.exports = coreClientSideValidation;
