
'use strict';

function recaptchaPromise(e, args) {
    var actionName = 'submit' + (e && e.target ? (e.target.id + e.target.className).replace(/[^a-zA-Z0-9]+/g, '') : '');

    // return Promise
    return grecaptcha.execute(window.CachedData.recaptchaSiteKey, {action: actionName}).then(function(token) {
        var recaptchaTokenInput = $("<input type='hidden' class='form-control' id='recaptchaToken' name='dwfrm_recaptcha_recaptchaToken' value=" + token + ">");
        var recaptchaActionInput = $("<input type='hidden' class='form-control' id='recaptchaAction' name='dwfrm_recaptcha_recaptchaAction' value=" + actionName + ">");

        if (typeof args.data == 'string') {
            args.data += args.data.length === 0 ? '' : '&';
            args.data += $.param(recaptchaTokenInput) + '&' + $.param(recaptchaActionInput);
        } else {
            args.data = args.data || {};
            args.data['dwfrm_recaptcha_recaptchaToken'] = token;
            args.data['dwfrm_recaptcha_recaptchaAction'] = actionName;
        }

        // Updated AJAX Arguments
        return args;
    });
}

function check(e, next) {
    var defer = $.Deferred();

    // Rejected or Resolved trigger ajax to trigger success, error, complete
    defer.always((args) => {
        var $xhr = args && $.ajax(args);

        defer.notify($xhr);

        return $xhr;
    });

    if (typeof grecaptcha !== 'undefined' && grecaptcha?.ready) {
        grecaptcha.ready(function() {
            module.exports.recaptchaPromise(e, next).then(function (args) {
                // success
                return defer.state() !== 'resolved' && defer.resolve(args); // with native beforeSend
            }, function (args) {
                // override beforeSend to kill the ajax send and allow error fn to trigger
                args.beforeSend = () => false;
                return defer.state() !== 'rejected' && defer.reject(args);
            });
        });
    } else {
        // Fall through to AJAX method
        defer.resolve(next);
    }

    return defer;
}

module.exports = {
    check: check,
    recaptchaPromise: recaptchaPromise
}
