'use strict';

const abSlider = require('core/components/slider');

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, assets, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide no-zoom');
        var $imageElement = $('<img/>');

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        $slideElement.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    abSlider.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
}

module.exports = abSlider;
