'use strict';

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * Clear previous error messages
 *
*/
function clearErrors() {
    var $form = $('.email-signup-form form');
    var $inputs = $form.find(':input:not(:disabled)');

    $inputs.each(function () {
        var $input = $(this);
        var $inputwrapper = $input.closest('.input-wrapper');
        $inputwrapper.find('.alert-danger').hide();
        $inputwrapper.removeClass('is-invalid');
    });
}

/**
 * Display message to user based on the response from the server
 * @param {Object} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    var status = data.success ? 'alert-success' : 'alert-danger';

    if (data.success) {
        $('.email-signup-message-custom').append('<div class="text-center alert ' + status + '">' + data.msg + '</div>');
    }
    else {
        var $form = $('.email-signup-form form');

        clearErrors();

        data.errors.forEach(function (error) {
            var $input = $form.find(error.selector);
            var $inputwrapper = $input.closest('.input-wrapper');
            $inputwrapper.find('.alert-danger').text(error.msg).show();
            $inputwrapper.addClass('is-invalid');
        });
    }
}

var footer = {
    'elementNameMap': {
        'hpEmailSignUp': 'emailId',
        'hpFirstNameSignUp': 'firstName',
        'hpLastNameSignUp': 'lastName'
    },
    'backToTop': function () {
        $('.back-to-top').click(function () {
            scrollAnimate();
        });
    },
    'forms': 'footer .email-signup-form form',
    'init': function () {
        var nameMap = this.elementNameMap;

        // Prevent Multi Form event collision
        var $forms = $(this.forms);
        $forms.each(function () {
            var $form = $(this);
            var $inputs = $form.find(':input:not(:disabled)');

            //TODO: Security Update with CSRF

            // Update Form Name for SFRA compatibility
            if (nameMap) {
                $inputs.each(function () {
                    var $input = $(this);
                    var oldName = $input.attr('name');
                    var newName = oldName && nameMap[oldName];

                    if (newName) {
                        $input.attr('name', newName);
                    }
                });
            }

            // Clear any previous error messages
            $inputs.each(function () {
                var $input = $(this);
                var $inputwrapper = $input.closest('.input-wrapper');
                $inputwrapper.find('.alert-danger').hide();
                $inputwrapper.removeClass('is-invalid');
            });

            $form.on('submit', function (e, urlOverride) {
                e.preventDefault();

                // Clear any previous error messages
                clearErrors();

                var isError = false;
                var $btn = $(this).find('button');
                var xhr = $form.data('xhr');

                // interate through the form inputs and if 'required' is set, check if the input is empty
                // if empty use data-missing-error attribute value to display error message to parent element
                $inputs.each(function () {
                    var $input = $(this);
                    var required = $input.attr('required');
                    var missingError = $input.data('missing-error');
                    var value = $input.val();
                    if (required && !value) {
                        var $inputwrapper = $input.closest('.input-wrapper');
                        $inputwrapper.find('.alert-danger').text(missingError).show();
                        $inputwrapper.addClass('is-invalid');
                        isError = true;
                    }
                });

                if (isError) {
                    return;
                }

                $form.data('xhr', $.ajax({
                    url: urlOverride || $form.attr('action'),
                    type: $form.attr('method') || 'post',
                    data: $inputs.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        // Drop duplicate requests
                        xhr && xhr.abort && xhr.abort();

                        $.spinner().start();

                        $btn.attr('disabled', true);
                    },
                    success: function (data) {
                        console.log('success', data);
                        displayMessage(data);
                        if (data.success) {
                            $form.trigger('footer:signup:success', data);
                            $form[0].reset();
                        }
                        $btn.removeAttr('disabled');
                    },
                    error: function ($xhr) {
                        displayMessage($xhr.responseJSON);
                    },
                    complete: function () {
                        $.spinner().stop();
                    }
                }));
            })

            // For SFRA compatibility
            $form.find('.subscribe-email').on('click', function (e) {
                e.preventDefault();
                $form.trigger('submit', $(this).data('href'));
            });
        });
    }
};

module.exports = footer;
