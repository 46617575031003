'use strict';

var corePDPBase = require('core/product/base');
var toastHelpers = require('core/components/toast');

function getImprintData(formData) {
    var imprintStyleValue = formData.find('input[name="imprint-type"]:checked').val();
    var imprintNameValue = formData.find('input[name="imprint-text"]').val();
    var imprintNameMaxLength = 22;
    var imprintData = {};

    if (imprintStyleValue.length > 0 && imprintNameValue.length > 0) {
        if (imprintNameValue.length > imprintNameMaxLength) {
            imprintNameValue = imprintNameValue.slice(0, imprintNameMaxLength);
        }

        imprintData = {
            imprintStyle: imprintStyleValue,
            imprintName: imprintNameValue
        };
    }

    return imprintData;
}

function validatePDPImprintName(imprintNameValue) {
    var regExp = new RegExp(/^[A-Z0-9\-&().?\s]+$/gi);
    var textErrorContainer = $('.imprinting-text-error');

    if (imprintNameValue != '' && !regExp.test(imprintNameValue)) {
        var errorMsg = textErrorContainer.data('invalid-text-msg');
        textErrorContainer.html(errorMsg);
        textErrorContainer.show();
        return false;
    } else {
        if (textErrorContainer.html()) {
            textErrorContainer.html('');
            textErrorContainer.hide();
        }
        return true;
    }
}

corePDPBase.addToCart = function() {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('input', 'input[name="imprint-text"]', function (event) {
        var imprintNameValue = $(this).val();

        validatePDPImprintName(imprintNameValue);
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        $('body').trigger('product:beforeAddToCart', this);

        // Check if we have the imprint checkbox on PDP and if so is it checked. Validate other options for imprinting if it is checked
        // and move on with add to cart if it is not there or is not checked.
        var imprintCheckbox = $('.imprintable input[name="imprint-enabled"]');
        var imprintDataString = null;

        if (imprintCheckbox.length > 0 && imprintCheckbox.is(':checked')) {
            var imprintData = getImprintData($('#pdp-imprint-form'));

            if ((Object.keys(imprintData).length > 0)) {
                if (validatePDPImprintName(imprintData.imprintName)) {
                    imprintData.quantity = parseInt(quantity);
                    imprintDataString = JSON.stringify(imprintData);
                } else {
                    return false;
                }
            } else {
                $('.imprinting-error').show();
                return false;
            }

        }

        if (!$('.imprinting-error').is(':hidden')) {
            $('.imprinting-error').hide();
        }

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer),
            imprintData: imprintDataString
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    } else {
                        toastHelpers.methods.show('warning', data.message, false);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

module.exports = corePDPBase;
